@import 'src/assets/sass/abstracts/variables';
@import 'src/assets/sass/abstracts/mixins';
@import 'react-toastify/dist/ReactToastify.css';

:root {
  --toastify-font-family: $text-font-stack;
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  border-radius: get-theme-spacing(2);
  padding: 0;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  min-height: initial;
  padding: 0;
}

.Toastify__toast-body {
  height: 100%;
  width: 190%;
  padding: 0;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  position: absolute;
  top: 7px;
  right: 10px;
  color: white;
  opacity: 0.9;
}

.toast {
  padding: 10px 20px;
  color: white;

  &--error {
    background-color: $color-error;
  }

  &--success {
    background-color: $color-success;
  }

  &--warn {
    //background-color: ;
  }

  &--info {
    color: $color-dark-100;
    background-color: $color-dark-70;
  }

  .message {
    font-size: $font-s-xsmall;
  }
}
