// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: $text-font-stack;
  font-size: 1rem;
  line-height: 1.5;
  background-color: $color-white;
  color: $color-dark-100;
}

button {
  font: inherit;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: $color-primary;
}

ul {
  padding: 0;
  margin: 0;

  &::marker {
    margin-left: -5px;
    text-indent: -5px !important;
  }
}
