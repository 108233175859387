@import 'src/assets/sass/abstracts/variables';
@import 'src/assets/sass/abstracts/mixins';

.btn {
  font-weight: $font-w-semi-bold;
  font-size: $font-s-small;
  padding: 13px 24px;
  border: none;
  border-radius: get-theme-spacing(1);

  &--contained {
    background-color: $color-primary;
    color: white;

    &:hover {
      background-color: #2b758c;
    }

    &:disabled {
      background-color: $color-dark-70;
    }
  }

  &--outlined {
    border: 1px solid $color-dark-100;
    background-color: $color-white;
    color: $color-dark-100;

    &:hover {
      background-color: $color-white;
      color: $color-dark-90;
      border: 1px solid $color-dark-90;
    }

    &:disabled {
      background-color: $color-white;
      color: $color-dark-70;
      border: 1px solid $color-dark-70;
    }
  }

  &--loading {
    background-color: $color-primary;
    color: white;

    &:hover {
      background-color: #2b758c;
    }

    &:disabled {
      background-color: $color-primary-light;
    }
  }

  &--text {
    color: $color-primary;
    font-size: $font-s-regular;
    background: #fff;
    text-decoration: underline;
    text-underline-position: under;
    text-underline-offset: 2px;
  }
}
