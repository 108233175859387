@import 'src/assets/sass/abstracts/variables';

.ldsRing {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 8px;
    border: 4px solid $color-primary;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-primary transparent transparent transparent;
  }

  &--small {
    width: 20px;
    height: 20px;

    div {
      width: 20px;
      height: 20px;
      border: 2px solid $color-white;
      border-color: $color-white transparent transparent transparent;
    }
  }
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
