// Font family
$text-font-stack: 'Inter', serif;

// Text Color
$color-primary: #3695b2;
$color-primary-light: #4e98b0;
$color-primary-dark: #2d6c9e;

// Success and Error color
$color-success: #58c6a5;
$color-error: #e35858;

// Dark color shades
$color-dark-100: #1a1e25;
$color-dark-90: #676d79;
$color-dark-80: #8a9090;
$color-dark-70: #d5d7d7;
$color-dark-60: #f2f3f3;
$color-dark-50: #192a3e;
$color-white: #fff;

// Shades
$color-shade-1: #ecf1f6;
$color-shade-2: #f5f9ff;
$color-shade-3: #f7fdff;
$color-shade-4: #eaf9fe;

// font weights
$font-w-light: 300;
$font-w-regular: 400;
$font-w-medium: 500;
$font-w-semi-bold: 600;
$font-w-bold: 700;

// font size
$font-s-xxsmall: 12px;
$font-s-xsmall: 13px;
$font-s-small: 14px;
$font-s-regular: 16px; // -> base size
$font-s-medium: 18px;
$font-s-big: 20px;
$font-s-large: 21px;
$font-s-xlarge: 24px;
$font-s-xxlarge: 32px;
$font-s-xxxlarge: 50px;

// Theme spacing
$theme-spacing: 8;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;
