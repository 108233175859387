@import 'src/assets/sass/abstracts/variables';
@import 'src/assets/sass/abstracts/mixins';

// Delete this file and other scss
.fieldfff {
  grid-column: span 2;

  &--sm {
    grid-column: span 1 !important;
  }

  &__label {
    display: block;
    margin-bottom: get-theme-spacing(1);
    &--optional::after {
      content: '\ (optional)';
      font-size: $font-s-small;
      font-weight: $font-w-regular;
    }
  }

  &__input {
    height: 48px;
    border: solid 1px $color-dark-70;
    border-radius: 8px;
    padding: 0.75rem 1rem 0.75rem 1rem;
    line-height: 24px;
    font-size: $font-s-regular;
    font-weight: $font-w-regular;
    font-family: inherit;
    width: 100%;
    color: $color-dark-90 !important;

    &:focus-visible {
      outline: none;
      border: solid 1px $color-primary;
    }

    &::placeholder {
      font-size: $font-s-regular;
      font-weight: $font-w-regular;
      color: $color-dark-70;
    }

    &--error {
      border-color: $color-error !important;
    }
  }

  & textarea {
    overflow: auto;
    height: 116px;
    resize: none;
  }

  &__helper-error {
    margin-top: 6px;
    color: $color-error;
    font-size: 13px;
  }

  @media (max-width: 600px) {
    grid-column: span 1;
  }
}

.input-box {
  display: flex;
  align-items: center;
  height: 48px;
  & input {
    font-family: inherit;
    border: none;
    font-size: $font-s-regular;
    width: calc(100% - 50px);
    font-weight: $font-w-regular;
    height: 40px;
    color: $color-dark-90 !important;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      font-size: $font-s-regular;
      font-weight: $font-w-regular;
      color: $color-dark-70;
    }
  }
  & .prefix {
    margin-right: 5px;
  }
}
