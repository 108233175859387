@import 'src/assets/sass/abstracts/variables';

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  font: inherit;
  font-weight: $font-w-medium;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $color-white;
  border: solid 1px $color-dark-70;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: $color-primary;
  border-color: $color-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  content: url('../../images/icons/check.svg');
}

@media (max-width: 600px) {
  .checkboxContainer {
    padding-bottom: get-theme-spacing(4);
  }
}
