@import 'src/assets/sass/abstracts/variables';

h1 {
  font-size: $font-s-xxxlarge;
}

h2 {
  font-size: $font-s-xxlarge;
  font-weight: 600;
}

h3 {
  font-size: $font-s-xlarge;
}

h4 {
  font-size: $font-s-large;
}

h5 {
  font-size: $font-s-medium;
}

h6 {
  font-size: $font-s-small;
  font-weight: $font-w-medium;
}

label {
  font-size: $font-s-small;
  font-weight: $font-w-semi-bold;
}
